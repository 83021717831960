import React from "react";

import { Formik, Form, Field } from "formik";
import {
	Button,
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select,
	Stack,
} from "@mui/material";
import { AxiosResponse } from "axios";

import { AdditionFormProps } from "../../form-generator";
import { IUpdateLegacyDataItem } from "../../models/metering";
import { updateLegacyDataItemFormValidationSchema as validationSchema } from "../metering/change-of-registration-data";
import { ThemisDateField } from "../forms/themis-date-field";
import { TalosMpanField } from "../forms/talos-mpan-field";
import { useAutofill } from "../../hooks";
import { fetchAllowedSscIds } from "../../api/metering";
import { PCId } from "../../models/metering/common";
import { validatedFieldValueOrUndefinedSync } from "../../utilities";

export const UpdateLegacyDataItemForm = ({
	formSubmitMutation,
	formData,
}: AdditionFormProps<IUpdateLegacyDataItem, AxiosResponse>) => {
	const [setQueryParameterValues, setForm, queryResult] = useAutofill<
		AxiosResponse,
		IUpdateLegacyDataItem,
		{ pcId?: string; mpan: string }
	>(
		"sscid",
		async (authContext, queryParams) => {
			return await fetchAllowedSscIds(
				authContext,
				Number(queryParams.mpan),
				queryParams.pcId
			);
		},
		() => {
			// A noop here as we are relying on the query result to update the form
			// definition itself rather than set form options
		}
	);

	return (
		<Formik<IUpdateLegacyDataItem>
			onSubmit={(values: IUpdateLegacyDataItem, { resetForm }) => {
				const valuesToSubmit: IUpdateLegacyDataItem = {
					mpan: Number(values.mpan),
					pcId: values.pcId || undefined, // Optional pcId
					pcEffectiveFromSettlementDate:
						values.pcEffectiveFromSettlementDate || undefined,
					sscId: values.sscId || undefined,
					sscEffectiveFromSettlementDate:
						values.sscEffectiveFromSettlementDate || undefined,
				};

				return formSubmitMutation.mutate(valuesToSubmit, {
					onSuccess: () => resetForm(),
				});
			}}
			initialValues={formData}
			validationSchema={validationSchema}
			validateOnChange
			enableReinitialize
		>
			{(form) => {
				setForm(form);

				const mpan = validatedFieldValueOrUndefinedSync<
					IUpdateLegacyDataItem,
					string
				>("mpan", form.values, validationSchema);

				if (mpan) {
					setQueryParameterValues({ mpan: `${mpan}`, pcId: form.values.pcId });
				}

				return (
					<Form>
						<Stack gap={2}>
							<TalosMpanField fieldName="mpan" label="MPAN*" form={form} />
							<FormControl>
								<InputLabel id={"pc_id_label"} error={!!form.errors.pcId}>
									{"PC Id"}
								</InputLabel>
								<Field
									name="pcId"
									label="PC Id"
									labelId={"pc_id_label"}
									as={Select}
									form={form}
									error={!!form.errors.pcId}
								>
									{[["", "Select"], ...Object.entries(PCId)].map(
										(val, index) => (
											<MenuItem key={`pc_id_${index}`} value={val[0]}>
												{`${val[1]}${val[0] ? ` - ${val[0]}` : ""}`}
											</MenuItem>
										)
									)}
								</Field>
								<FormHelperText error={!!form.errors.pcId}>
									{form.errors.pcId}
								</FormHelperText>
							</FormControl>

							<ThemisDateField
								value={form.values.pcEffectiveFromSettlementDate!!}
								name="pcEffectiveFromSettlementDate"
								label="PC Effective From Settlement Date"
								helperText={form.errors.pcEffectiveFromSettlementDate}
								error={!!form.errors.pcEffectiveFromSettlementDate}
								form={form}
							/>
							<FormControl>
								<InputLabel id={"ssc_id_label"} error={!!form.errors.sscId}>
									{"SSC Id"}
								</InputLabel>
								<Field
									name="sscId"
									label="SSC Id"
									labelId={"ssc_id_label"}
									as={Select}
									form={form}
									error={!!form.errors.sscId}
								>
									{[
										["", "Select"],
										...Object.entries(queryResult?.data?.data || {}),
									].map((val, index) => (
										<MenuItem key={`ssc_id_${index}`} value={val[0]}>
											{`${val[0]}${val[0] ? ` - ${val[1]}` : val[1]}`}
										</MenuItem>
									))}
								</Field>
								<FormHelperText error={!!form.errors.sscId}>
									{form.errors.sscId}
								</FormHelperText>
							</FormControl>
							<ThemisDateField
								value={form.values.sscEffectiveFromSettlementDate!!}
								name="sscEffectiveFromSettlementDate"
								label="SSC Effective From Settlement Date"
								helperText={form.errors.sscEffectiveFromSettlementDate}
								error={!!form.errors.sscEffectiveFromSettlementDate}
								form={form}
							/>
						</Stack>
						<Stack direction={"row"} gap={2} marginTop={2}>
							<Button
								variant="contained"
								type="submit"
								disabled={!form.isValid || formSubmitMutation.isLoading}
							>
								Submit
							</Button>
						</Stack>
					</Form>
				);
			}}
		</Formik>
	);
};
