import { Field, FormikProps, FormikValues } from "formik";
import { TextField } from "@mui/material";

type PropertiesWithNumericValues<T> = {
	[P in keyof T]: T[P] extends number ? P : never;
}[keyof T];

export const TalosMpanField = <
	TFormValues extends FormikValues = FormikValues
>({
	label,
	form,
	fieldName,
	disabled,
}: {
	label: string;
	form: FormikProps<TFormValues>;
	fieldName: PropertiesWithNumericValues<FormikValues>;
	disabled?: boolean;
}) => {
	return (
		<Field
			name={fieldName}
			data-cy={fieldName}
			label={label}
			disabled={disabled}
			as={TextField}
			type="number"
			InputProps={{
				slotProps: {
					input: {
						min: 0,
						// Scrolling with focus in this field results in change to input
						// values. Unfortunately MUI's API for composing the input field
						// and their suggested base number component are poorly documented,
						// poorly implemented and left in a halfway state. This is a known
						// issue. Best fix at present is to remove focus from the field on
						// scroll.
						onWheel: (event: InputEvent) =>
							event.target instanceof HTMLElement && event.target.blur(),
					},
				},
			}}
			onChange={(e: any) => {
				e.preventDefault();
				const { value } = e.target;
				if (value.toString().length === 0) {
					form.setFieldValue(fieldName, "");
				} else if (value.toString().length <= 13)
					form.setFieldValue(fieldName, Number(value));
			}}
			error={!!form.errors[fieldName]}
			helperText={form.errors[fieldName]}
		/>
	);
};
