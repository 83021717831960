import { useCallback, useState } from "react";

/**
 * Allow useState from within render phase of the Formik form render callback.
 * Performs normal setState call within a callback to setTimeout (with timeout
 * of 0, equivalent to the function setImmediate which failed to become a standard
 * but has a descriptive name, hence this function name referring to it) so that
 * the setState call is pushed to the top of the event loop to be performed as
 * soon as the currently queued work is finished.
 * NB: Use with care, this causes state to be set outside the current render
 * loop which will result in the containing component rendering again if used
 * with a non-primitive type.
 */
export const useStateImmediate = <T>(initialValue?: T | (() => T)) => {
	const [state, setState] = useState(initialValue);

	return [
		state,
		useCallback(
			(newState: T) =>
				setTimeout(() => {
					setState(newState);
				}, 0),
			[]
		),
	] as [T, (newState: T) => void];
};
