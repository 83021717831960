import { useMemo, useState } from "react";

import { getD0010SearchParams, getDMEXRequests } from "../../api/talos";
import { flowsTable } from "../../components/tables/flows-table";
import { PagedRequestsPage } from "../requests-list-page";
import {
	IMpxnByDateFilterProps,
	MpxnByDateFilter,
} from "../../components/filters/mpxn-by-date-filter";
import { DMEXResponse, RegisterData } from "../../models/talos/dmex";

const isResponseKey = (key: string): key is keyof DMEXResponse =>
	[
		"status",
		"agentName",
		"mpan",
		"msn",
		"readDate",
		"createdAt",
		"reg1Data",
		"reg2Data",
	].includes(key);

const isRegDataKey = (key: string | undefined): key is keyof RegisterData =>
	key !== undefined && ["regId", "final", "install"].includes(key);

const DMEXTable = flowsTable<DMEXResponse>({
	columns: [
		{ label: "STATUS", key: "status" },
		{ label: "Agent Name", key: "agentName" },
		{ label: "MPAN", key: "mpan" },
		{ label: "MSN", key: "msn" },
		{ label: "Read Date", key: "readDate" },
		{ label: "Register 1 ID", key: "reg1Data.regId" },
		{ label: "Register 1 Final", key: "reg1Data.final" },
		{ label: "Register 1 Install", key: "reg1Data.install" },
		{ label: "Register 2 ID", key: "reg2Data.regId" },
		{ label: "Register 2 Final", key: "reg2Data.final" },
		{ label: "Register 2 Install", key: "reg2Data.install" },
		{ label: "Created At", key: "createdAt" },
	],
	dataKey: "dmex",

	getId: (response: DMEXResponse) => response.id,
	getColumnContent: (columnKey, response) => {
		// Look up value based on where in the response type the column key exists.
		if (isResponseKey(columnKey)) return response[columnKey];

		const [regKey, regDataKey] = columnKey.split(".");

		if (regKey && regDataKey) {
			const regData = isResponseKey(regKey)
				? (response[regKey] as RegisterData)
				: undefined;
			const regDataValue = isRegDataKey(regDataKey)
				? regData?.[regDataKey]
				: undefined;

			return regDataValue;
		}

		throw Error("Unknown column key");
	},
});

export const DMEXRequests = () => {
	const [filterState, setFilterState] = useState<IMpxnByDateFilterProps>({});
	const { mpxn, start, end } = filterState;
	const searchParams = useMemo(
		() => getD0010SearchParams(mpxn, start, end),
		[mpxn, start, end]
	);
	return (
		<PagedRequestsPage<DMEXResponse>
			title="DMEX"
			requestsName={"DMEX"}
			requestsKey={"dmex-requests"}
			getRequests={getDMEXRequests}
			RequestsTable={DMEXTable}
			requestsOptions={
				<MpxnByDateFilter
					handleSubmit={setFilterState}
					initialValues={filterState}
					filterType="MPAN"
				/>
			}
			additionLink={"/add-dmex"}
			getRequestsOptions={searchParams}
		/>
	);
};
