import { IInstallRemoveMeter } from "../models/metering";

export const mapInstallRemoveMeterFormValuesToRequest = (
	values: IInstallRemoveMeter
) => {
	const installRemoveMeterRequest: IInstallRemoveMeter = {
		mpan: values.mpan,
		transactionId:
			values.transactionId !== "" ? values.transactionId : undefined,
		appointmentDate:
			values.appointmentDate !== "" ? values.appointmentDate : undefined,
		earliestAppointmentTime:
			values.earliestAppointmentTime !== ""
				? new Date(values.earliestAppointmentTime!!).toLocaleTimeString("en-GB")
				: undefined,
		latestAppointmentTime:
			values.latestAppointmentTime !== ""
				? new Date(values.latestAppointmentTime!!).toLocaleTimeString("en-GB")
				: undefined,
		retrievalMethod: values.retrievalMethod,
		requestedEnergisationStatus: values.requestedEnergisationStatus,
		standardSettlementConfigurationId:
			values.standardSettlementConfigurationId !== ""
				? values.standardSettlementConfigurationId
				: undefined,
		additionalInformation:
			values.additionalInformation !== ""
				? values.additionalInformation
				: undefined,
		removeMeteringPointMeters:
			values.removeMeteringPointMeters !== "" &&
			values.removeMeteringPointMeters !== undefined
				? values.removeMeteringPointMeters === "true"
				: undefined,
		meteringSystemNonSettlementFunctionalityCode:
			values.meteringSystemNonSettlementFunctionalityCode !== ""
				? values.meteringSystemNonSettlementFunctionalityCode
				: undefined,
		communicationsMethod:
			values.communicationsMethod !== ""
				? values.communicationsMethod
				: undefined,
	};
	return installRemoveMeterRequest;
};
