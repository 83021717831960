import { useCallback, useRef } from "react";

import { hashQueryKey, QueryKey, useQuery } from "react-query";
import { FormikProps, FormikValues } from "formik";
import { QueryObserverOptions } from "react-query/types/core/types";

import { IAuthContext, useAuth } from "../auth";
import { notEmpty } from "../utilities/predicates";
import { useStateImmediate } from "./useStateImmediate";

/**
 * `useAutofill` is a custom hook designed to fetch data based on query parameters
 * and automatically populate a form with the retrieved data.
 *
 * @template TResponse - The type of the data returned from the fetch function.
 * @template TFormValues - The type of the values in the form.
 * @template TQueryParams - The type of the query parameters used for fetching data.
 *
 * @param {string} queryKey - A unique key used by `react-query` to identify and cache the query.
 * @param {(authContext: IAuthContext, queryParams: TQueryParams) => Promise<TResponse>} fetchFn - A function that fetches the data.
 *   It receives the authentication context and query parameters and returns a promise that resolves with the data.
 * @param {(response: TResponse, form: FormikProps<TFormValues>) => void} autoFillFields - A function that takes the fetched data
 *   and the form instance as arguments and populates the form fields.
 * @param queryOptions Options for useQuery omitting queryKey, queryFn and enabled options. Defaults to no refetching,
 *   fetched data is considered final.
 * @returns {[typeof setQueryParameterValues, typeof setForm, typeof queryResult]} - A tuple containing:
 *   - `setQueryParameterValues`: A function to set the query parameter values.
 *   - `setForm`: A function to set the Formik form instance.
 *   - `queryResult`: The result object returned by `react-query`'s `useQuery` hook.
 */
export const useAutofill = <
	TResponse,
	TFormValues extends FormikValues,
	TQueryParams extends Record<string, any>
>(
	queryKey: string,
	fetchFn: (
		authContext: IAuthContext,
		queryParams: TQueryParams
	) => Promise<TResponse>,
	autoFillFields: (
		response: TResponse,
		form: FormikProps<TFormValues>
	) => void = () => {},
	queryOptions: Omit<
		QueryObserverOptions<TResponse>,
		"queryKey" | "queryFn" | "enabled"
	> = {
		retry: false,
		refetchOnWindowFocus: false,
		refetchOnMount: false,
		refetchOnReconnect: false,
	}
): [typeof setQueryParameterValues, typeof setForm, typeof queryResult] => {
	const [queryParameterValues, setQueryParameterValues] =
		useStateImmediate<TQueryParams>();

	const formRef = useRef<FormikProps<TFormValues> | null>(null);
	const setForm = useCallback(
		(form: FormikProps<TFormValues>) => (formRef.current = form),
		[formRef]
	);

	// Ref to store hash of current query parameter values so we don't setState if
	// they haven't changed
	const hashRef = useRef<string | undefined>(undefined);

	const setQueryParameterValuesCallback = useCallback(
		(value: TQueryParams) => {
			const hashedNewValue = hashQueryKey([value]);

			if (hashRef.current === hashedNewValue) return;

			hashRef.current = hashedNewValue;
			setQueryParameterValues(value);
		},
		[setQueryParameterValues]
	);

	const hasQueryParameterValues =
		queryParameterValues != undefined
			? !!Object.values(queryParameterValues).filter(notEmpty).length
			: false;

	const fetchEnabled = hasQueryParameterValues && formRef.current != null;

	const authContext = useAuth();

	const queryResult = useQuery({
		...queryOptions,
		queryKey: [queryKey, queryParameterValues] as QueryKey,
		queryFn: async () => {
			const response = await fetchFn(authContext, queryParameterValues!);

			autoFillFields(response, formRef.current!);

			return response;
		},
		// Only autofill if we have set a value and the form has rendered
		enabled: fetchEnabled,
	});

	return [setQueryParameterValuesCallback, setForm, queryResult] as [
		typeof setQueryParameterValues,
		typeof setForm,
		typeof queryResult
	];
};
