export interface IInstallRemoveMeter {
	mpan: number | "";
	transactionId?: string;
	appointmentDate?: string;
	earliestAppointmentTime?: string;
	latestAppointmentTime?: string;
	retrievalMethod: string;
	requestedEnergisationStatus: string;
	standardSettlementConfigurationId?: string;
	additionalInformation?: string;
	removeMeteringPointMeters?: boolean | "" | string;
	meteringSystemNonSettlementFunctionalityCode?: string;
	communicationsMethod?: string;
}

export enum RetrievalMethod {
	H = "Visual",
	M = "Manual",
	N = "Not known at time appointment or request for meter installation",
	R = "Remote reading",
	S = "Supplier sourced HH smart readings",
	U = "Unmetered reading",
}

export const initialInstallRemoveMeterFormValues: IInstallRemoveMeter = {
	mpan: "",
	transactionId: "",
	appointmentDate: "",
	earliestAppointmentTime: "",
	latestAppointmentTime: "",
	retrievalMethod: "",
	requestedEnergisationStatus: "",
	standardSettlementConfigurationId: "",
	additionalInformation: "",
	removeMeteringPointMeters: "",
	meteringSystemNonSettlementFunctionalityCode: "",
	communicationsMethod: "",
};
