export enum EnergisationStatus {
	"De-Energised" = "D",
	"Energised" = "E",
}

export enum MeterLocation {
	A = "A",
	B = "B",
	C = "C",
	D = "D",
	E = "E",
	F = "F",
	G = "G",
	H = "H",
	I = "I",
	J = "J",
	K = "K",
	L = "L",
	M = "M",
	N = "N",
	O = "O",
	P = "P",
	R = "R",
	S = "S",
	T = "T",
	U = "U",
	V = "V",
	W = "W",
	X = "X",
	Y = "Y",
	Z = "Z",
}

export enum ReadingType {
	"Non Half-Hourly" = "N",
	"Half-Hourly" = "H",
}

export enum IHDStatus {
	"INSTALLED" = "I",
	"DECLINED" = "D",
	"EXISTING" = "E",
	"FAILED" = "F",
}

export enum PCId {
	DOMESTIC_UNRESTRICTED = "1",
	DOMESTIC_ECONOMY_7 = "2",
	NON_DOMESTIC_UNRESTRICTED = "3",
	NON_DOMESTIC_ECONOMY_7 = "4",
	NON_DOMESTIC_MD_LOAD_FACTOR_0_20_PERCENT = "5",
	NON_DOMESTIC_MD_LOAD_FACTOR_20_30_PERCENT = "6",
	NON_DOMESTIC_MD_LOAD_FACTOR_30_40_PERCENT = "7",
	NON_DOMESTIC_MD_LOAD_FACTOR_40_PERCENT_PLUS = "8",
}

export enum MeasurementClassId {
	"Non Half Hourly Metered" = "A",
	"Non Half Hourly Unmetered" = "B",
	"HH metered in 100kW Premises" = "C",
	"Half Hourly Unmetered" = "D",
	"HH metered sub 100kW CT" = "E",
	"HH metered sub 100kW domestic" = "F",
	"HH metered sub 100kW nondom wc" = "G",
}

export enum SMETSVersion {
	"SMETS Meter No Longer Installed" = "NONE",
	"SMETS Version 1" = "SMETS1",
	"SMETS Version 2" = "SMETS2",
}

export enum CommunicationsMethod {
	"Generation Mobile Telephony Standard" = "3G",
	"Caller Display or Caller Line Identifier modem on PSTN" = "CD",
	"Circuit Switched Data (CSD)" = "CS",
	"GSM Fax" = "GF",
	"General Packet Radio Service (GPRS)" = "GP",
	"Global System for Mobile Communications (GSM)" = "GS",
	"GSM Voice comms" = "GV",
	"Permanent Hand Held Reads" = "HP",
	"Temporary Hand Held Reads" = "HT",
	"Hybrid" = "HY",
	"Internet Protocol" = "IP",
	"ISDN Link" = "IS",
	"Mains Signalling" = "MS",
	"Paknet" = "PK",
	"Private Networks" = "PN",
	"PSTN" = "PS",
	"SMS (Short Message Service e.g. text message)" = "SM",
}
