import { useContext, useState } from "react";

import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	MenuItem,
	Typography,
} from "@mui/material";
import { parse } from "date-fns";
import { Form, Formik } from "formik";
import { AxiosError, AxiosResponse } from "axios";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import { IAddD0205Flow } from "../../models/talos/d0205-flows";
import { AdditionFormProps, useOnFirstTouch } from "../../form-generator";
import {
	EnergisationStatus,
	IHDStatus,
	PCId,
	MeasurementClassId,
	ReadingType,
	SMETSVersion,
} from "../../models/metering/common";
import {
	TalosTextBox,
	TalosDropDown,
	TalosButton,
	TalosDateField,
} from "../forms";
import { addD0205FlowFormValidationSchema } from "./add-d0205-flow-form.validation-schema";
import { TalosMpanField } from "../forms/talos-mpan-field";
import { AuthContext } from "../../auth";
import {
	AlertContext,
	handleError,
	validatedFieldValueOrUndefinedSync,
} from "../../utilities";
import { getFlowStatusByType } from "../../api/talos";
import { theme } from "../../styles/theme";
import { useAutofill } from "../../hooks";
import { fetchAllowedSscIds, getTechnicalDetails } from "../../api/metering";

export function mapFormToValues(formValues: IAddD0205Flow): IAddD0205Flow {
	const conditionalFields: Record<string, string> = {
		effectiveFromDateMSES: "energisationStatus",
		effectiveFromDateMSMC: "measurementClassId",
		meterTimeswitchCodeEffectiveFromDate: "meterTimeswitchCode",
		effectiveFromDateMSPC: "pcId",
		effectiveFromDateSCON: "sscId",
		dataAggregationType: "dataAggregatorId",
		effectiveFromDateDAA: "dataAggregatorId",
		dataCollectorType: "dataCollectorId",
		effectiveFromDateDCA: "dataCollectorId",
		meterOperatorType: "meterOperatorId",
		effectiveFromDateMOA: "meterOperatorId",
		effectiveFromDateIHDI: "ihdStatus",
		effectiveFromDateSMSO: "smartMeterOperatorId",
	};

	return Object.entries(formValues).reduce((acc, [key, value]) => {
		if (
			conditionalFields[key] &&
			!(formValues as any)[conditionalFields[key]]
		) {
			(acc as any)[key] = undefined;
		} else {
			(acc as any)[key] = value === "" ? undefined : value;
		}
		return acc;
	}, {} as IAddD0205Flow);
}

export const AddD0205FlowForm = ({
	formSubmitMutation,
	formData,
	onFirstTouch,
}: AdditionFormProps<IAddD0205Flow, AxiosResponse>) => {
	const [mpanValue, setMpanValue] = useState<number>();
	const [showWarning, setShowWarning] = useState(false);

	const authContext = useContext(AuthContext);
	const navigate = useNavigate();

	const handleTouched = useOnFirstTouch<IAddD0205Flow>(onFirstTouch);

	const { setTalosAlert } = useContext(AlertContext);

	const [
		setQueryParameterValuesSSCIdFilter,
		setFormSSCIdFilter,
		queryResultSSCIdFilter,
	] = useAutofill<
		AxiosResponse,
		IAddD0205Flow,
		{ mpan: string; pcId?: string }
	>(
		"sscId",
		async (authContext, queryParams) =>
			await fetchAllowedSscIds(
				authContext,
				Number(queryParams.mpan),
				queryParams.pcId
			),
		() => {}
	);

	const [
		setQueryParameterValuesTechDetails,
		setFormTechDetails,
		queryResultTechDetails,
	] = useAutofill<AxiosResponse, IAddD0205Flow, { mpan: string }>(
		"ecoes",
		async (authContext, queryParams) =>
			await getTechnicalDetails(authContext, queryParams.mpan),
		() => {}
	);

	const { isLoading } = useQuery({
		queryKey: ["d0205-status", authContext, mpanValue],
		queryFn: () => getFlowStatusByType(authContext, mpanValue!!, "s76", 7),
		enabled: mpanValue !== undefined && mpanValue.toString().length === 13,
		retry: false,
		refetchOnWindowFocus: false,
		refetchOnMount: false,
		onSuccess: () => {
			setShowWarning(true);
		},
		onError: (e: AxiosError) => {
			if (e.response && e.response.status !== 404) {
				const errorId = handleError(e);
				setTalosAlert({
					message: `Something went wrong while checking flow status for the given mpan, please try again later or contact IOPS Support. Ticket ID: ${errorId}`,
					severity: "error",
				});
			}
		},
	});

	const transformEcoesResponse = (
		queryResultTechDetailsData: AxiosResponse<any, any> | undefined
	) => {
		if (
			queryResultTechDetailsData?.data &&
			typeof queryResultTechDetailsData.data === "string" &&
			queryResultTechDetailsData.data.includes("ErrorCode")
		) {
			return undefined;
		}

		return queryResultTechDetailsData?.data?.["Results"]?.[0]?.[
			"UtilityMatches"
		]?.[0]?.["UtilityDetails"]
			.filter((detail: { ["Key"]: string; ["Value"]: string | null }) =>
				detail["Key"].endsWith("_efd")
			)
			.map((detail: { ["Key"]: string; ["Value"]: string | null }) => {
				return {
					...detail,
					Value: detail.Value
						? parse(detail.Value, "yyyyMMdd", new Date())
						: null,
				};
			});
	};

	return (
		<>
			<Dialog
				data-cy={"notification-modal"}
				open={showWarning}
				onClose={() => {
					setShowWarning(false);
				}}
				sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
			>
				<DialogTitle
					sx={{
						background: theme.palette.warning.light,
					}}
				>
					Recent D0205 flow found for this MPAN. Do you wish to proceed?
				</DialogTitle>
				<DialogContent dividers>
					<Typography variant="body1">MPAN: {mpanValue}</Typography>
				</DialogContent>
				<DialogActions>
					<Button
						data-cy={"notification-modal-button-left"}
						variant="outlined"
						onClick={() => setShowWarning(false)}
					>
						YES
					</Button>
					<Button
						data-cy={"notification-modal-button-right"}
						variant="contained"
						onClick={() => navigate("/")}
					>
						NO
					</Button>
				</DialogActions>
			</Dialog>

			<Formik
				enableReinitialize
				onSubmit={(values: IAddD0205Flow, { resetForm }) =>
					formSubmitMutation.mutate(values, {
						onSuccess: () => {
							resetForm();
							setMpanValue(undefined);
						},
					})
				}
				initialValues={formData}
				validationSchema={addD0205FlowFormValidationSchema(
					transformEcoesResponse(queryResultTechDetails.data)
				)}
				validateOnChange
			>
				{(form) => {
					handleTouched(form);
					setFormSSCIdFilter(form);
					setFormTechDetails(form);

					const mpan = validatedFieldValueOrUndefinedSync<
						IAddD0205Flow,
						string
					>("mpan", form.values, addD0205FlowFormValidationSchema());

					if (mpan) {
						setQueryParameterValuesSSCIdFilter({
							mpan,
							pcId: form.values.pcId,
						});
						setQueryParameterValuesTechDetails({
							mpan,
						});
					}

					return (
						<Form
							onBlur={() => {
								setMpanValue((prev) =>
									prev !== +form.values.mpan ? +form.values.mpan : prev
								);
							}}
						>
							<Box className="form-wrapper">
								<Box className="form-column">
									<TalosMpanField
										fieldName="mpan"
										label="MPAN*"
										form={form}
										disabled={isLoading}
									/>
									<TalosDropDown
										fieldName="energisationStatus"
										label="Energisation Status"
										form={form}
										menuItems={[
											["Select", ""],
											...Object.entries(EnergisationStatus),
										].map((val, index) => (
											<MenuItem
												key={`energisation_status_${index}`}
												value={val[1]}
											>
												{`${val[0]}${val[1] ? ` - ${val[1]}` : ""}`}
											</MenuItem>
										))}
									/>
									{form.values.energisationStatus && (
										<TalosDateField
											fieldName="effectiveFromDateMSES"
											label="Energ. Status Effective From Date"
											form={form}
										/>
									)}
									<TalosDropDown
										fieldName="measurementClassId"
										label="Measurement Class Id"
										form={form}
										menuItems={[
											["Select", ""],
											...Object.entries(MeasurementClassId),
										].map((val, index) => (
											<MenuItem
												key={`measurement_class_id${index}`}
												value={val[1]}
											>
												{`${val[0]}${val[1] ? ` - ${val[1]}` : ""}`}
											</MenuItem>
										))}
									/>
									{form.values.measurementClassId && (
										<TalosDateField
											fieldName="effectiveFromDateMSMC"
											label="Measurement Class Effective From Date"
											form={form}
										/>
									)}
									<TalosTextBox
										fieldName="meterTimeswitchCode"
										label="Meter Timeswitch Code"
										form={form}
									/>
									{form.values.meterTimeswitchCode && (
										<TalosDateField
											fieldName="meterTimeswitchCodeEffectiveFromDate"
											label="Meter Timeswitch Code Effective Date"
											form={form}
										/>
									)}

									<TalosDropDown
										fieldName="pcId"
										label="Profile Class Id"
										form={form}
										menuItems={[["", "Select"], ...Object.entries(PCId)].map(
											(val, index) => (
												<MenuItem key={`pc_id_${index}`} value={val[0]}>
													{`${val[1]}${val[0] ? ` - ${val[0]}` : ""}`}
												</MenuItem>
											)
										)}
									/>
									{form.values.pcId && (
										<TalosDateField
											fieldName="effectiveFromDateMSPC"
											label="Profile Class Effective From Date"
											form={form}
										/>
									)}
									{queryResultSSCIdFilter.data?.data !== undefined ? (
										<TalosDropDown
											fieldName="sscId"
											label="SSC Id"
											form={form}
											menuItems={[
												["", "Select"],
												...Object.entries(
													queryResultSSCIdFilter.data?.data || {}
												),
											].map((val, index) => (
												<MenuItem key={`sscId${index}`} value={val[0]}>
													{`${val[0]}${val[0] ? ` - ${val[1]}` : val[1]}`}
												</MenuItem>
											))}
										/>
									) : (
										<TalosTextBox
											fieldName="sscId"
											label="SSC Id"
											form={form}
										/>
									)}
									{form.values.sscId && (
										<TalosDateField
											fieldName="effectiveFromDateSCON"
											label="Standard Settlement Configuration Effective From Date"
											form={form}
										/>
									)}
									<h3>Agent Details:</h3>
									<TalosTextBox
										fieldName="dataAggregatorId"
										label="Data Aggregator Id"
										form={form}
									/>
									{form.values.dataAggregatorId && (
										<>
											<TalosDropDown
												fieldName="dataAggregationType"
												label="Data Aggregation Type"
												form={form}
												menuItems={[
													["Select", ""],
													...Object.entries(ReadingType),
												].map((val, index) => (
													<MenuItem
														key={`data_aggregation_type${index}`}
														value={val[1]}
													>
														{`${val[0]}${val[1] ? ` - ${val[1]}` : ""}`}
													</MenuItem>
												))}
											/>
											<TalosDateField
												fieldName="effectiveFromDateDAA"
												label="Data Aggregator Effective From Date"
												form={form}
											/>
										</>
									)}
									<TalosTextBox
										fieldName="dataCollectorId"
										label="Data Collector Id"
										form={form}
									/>
									{form.values.dataCollectorId && (
										<>
											<TalosDropDown
												fieldName="dataCollectorType"
												label="Data Collector Type"
												form={form}
												menuItems={[
													["Select", ""],
													...Object.entries(ReadingType),
												].map((val, index) => (
													<MenuItem
														key={`data_collector_type_${index}`}
														value={val[1]}
													>
														{`${val[0]}${val[1] ? ` - ${val[1]}` : ""}`}
													</MenuItem>
												))}
											/>
											<TalosDateField
												fieldName="effectiveFromDateDCA"
												label="Data Collector Effective From Date"
												form={form}
											/>
										</>
									)}
									<TalosTextBox
										fieldName="meterOperatorId"
										label="Meter Operator Id"
										form={form}
									/>
									{form.values.meterOperatorId && (
										<>
											<TalosDropDown
												fieldName="meterOperatorType"
												label="Meter Operator Type"
												form={form}
												menuItems={[
													["Select", ""],
													...Object.entries(ReadingType),
												].map((val, index) => (
													<MenuItem
														key={`meter_operator_type_${index}`}
														value={val[1]}
													>
														{`${val[0]}${val[1] ? ` - ${val[1]}` : ""}`}
													</MenuItem>
												))}
											/>
											<TalosDateField
												fieldName="effectiveFromDateMOA"
												label="MOP Effective From Date"
												form={form}
											/>
										</>
									)}
									<TalosDropDown
										fieldName="ihdStatus"
										label="IHD Status"
										form={form}
										menuItems={[
											["", "Select"],
											...Object.entries(IHDStatus),
										].map((val, index) => (
											<MenuItem key={`ihd_status_${index}`} value={val[0]}>
												{`${val[1]}${val[0] ? ` - ${val[0]}` : ""}`}
											</MenuItem>
										))}
									/>
									{form.values.ihdStatus && (
										<TalosDateField
											fieldName="effectiveFromDateIHDI"
											label="IHD Status Effective From Date"
											form={form}
										/>
									)}
									<TalosTextBox
										fieldName="smartMeterOperatorId"
										label="Smart Meter Operator Id"
										form={form}
									/>
									{form.values.smartMeterOperatorId && (
										<TalosDateField
											fieldName="effectiveFromDateSMSO"
											label="Smart Meter Operator Effective From Date"
											form={form}
										/>
									)}
									<TalosDropDown
										fieldName="smetsVersion"
										label="SMETS Version"
										form={form}
										menuItems={[
											["Select", ""],
											...Object.entries(SMETSVersion),
										].map((val, index) => (
											<MenuItem key={`smets_version${index}`} value={val[1]}>
												{`${val[0]}${val[1] ? ` - ${val[1]}` : ""}`}
											</MenuItem>
										))}
									/>
									<TalosButton
										fieldName="form_submit"
										form={form}
										buttonText="Submit"
										loading={formSubmitMutation.isLoading}
									/>
								</Box>
							</Box>
						</Form>
					);
				}}
			</Formik>
		</>
	);
};
