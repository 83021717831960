import React, {
	createContext,
	useCallback,
	useContext,
	useMemo,
	useRef,
} from "react";

import {
	FormikConfig,
	FormikProps,
	FormikValues,
	useFormikContext,
} from "formik";

import { IAuthContext } from "../auth";
import { useAutofill } from "../hooks";

/**
 * Hook which returns a function into which a formik form (FormikProps) object
 * may be passed to track first touch. This will fire when data is first entered
 * into any text/dropdown. This does not work for radios but should be sufficient
 * for most use cases.
 * @param onFirstTouch handler to invoke when form is first touched
 * @return Function which takes a form object to inspect for first touch.
 */
export const useOnFirstTouch = <TFormValues,>(
	onFirstTouch: (() => void) | undefined
) => {
	// Use a ref as a latch to prevent onFirstTouch being called more than once
	const latchRef = useRef(false);

	return useCallback(
		(form: FormikProps<TFormValues>) => {
			const initialTouchedKeys = Object.keys(form.initialTouched);
			const currentTouchedKeys = Object.keys(form.touched);
			const hasFreshlyTouchedKeys = currentTouchedKeys.some(
				(key) => !initialTouchedKeys.includes(key)
			);
			if (onFirstTouch && hasFreshlyTouchedKeys && !latchRef.current) {
				latchRef.current = true;
				onFirstTouch();
			}
		},
		[onFirstTouch]
	);
};

export type FormAutofillComponent<TFormValues extends FormikValues> = ({
	form,
	children,
}: {
	form: FormikProps<TFormValues>;
	children: React.ReactNode;
}) => React.JSX.Element;

export const useFormAutofillWrapper = <
	TFormValues extends FormikValues,
	TAutofillQueryParams extends Record<string, any>,
	TAutofillResponse = unknown
>(
	queryKey: string,
	fetchAutofillData: (
		authContext: IAuthContext,
		queryParams: TAutofillQueryParams
	) => Promise<TAutofillResponse | null>,
	queryParameterSelector: (
		formValues: TFormValues,
		validationSchema: FormikConfig<TFormValues>["validationSchema"]
	) => TAutofillQueryParams,
	autofillFields: (
		response: TAutofillResponse | null,
		formValues: FormikProps<TFormValues>
	) => void
) => {
	const [setDependentValues, setForm] = useAutofill<
		TAutofillResponse | null,
		TFormValues,
		TAutofillQueryParams
	>(queryKey, fetchAutofillData, autofillFields);

	const queryParamterSelectorRef = useRef(queryParameterSelector);
	queryParamterSelectorRef.current = queryParameterSelector;

	return useMemo(
		() =>
			function FormAutofillWrapper({
				children,
			}: {
				children: React.ReactNode;
			}) {
				const form = useFormikContext<TFormValues>();
				const validationSchema = useValidationSchemaFromGeneratorContext();

				setForm(form);
				setDependentValues(
					queryParamterSelectorRef.current(form.values, validationSchema)
				);
				return <>{children}</>;
			},
		[setForm, setDependentValues]
	);
};

type GeneratorConfig<TFormValues extends FormikValues> = {
	validationSchema: FormikConfig<TFormValues>["validationSchema"];
};

export const GeneratorContext = createContext<GeneratorConfig<FormikValues>>({
	validationSchema: undefined,
});

const useValidationSchemaFromGeneratorContext = () => {
	const { validationSchema } = useContext(GeneratorContext);
	return validationSchema;
};
