import { AxiosResponse } from "axios";

import { requestInstallRemoveMeter } from "../../../api/metering";
import { mapInstallRemoveMeterFormValuesToRequest } from "../../../utilities";
import {
	IInstallRemoveMeter,
	initialInstallRemoveMeterFormValues,
} from "../../../models/metering";
import { RequestInstallRemoveMeterForm } from "../../../components/metering/change-of-registration-data";
import { AdditionPage } from "../../addition-page";

export const RequestInstallRemoveMeter = () => (
	<AdditionPage<IInstallRemoveMeter, IInstallRemoveMeter, AxiosResponse>
		mapFormToValues={mapInstallRemoveMeterFormValuesToRequest}
		header="Request Install/Remove Meter"
		initialValues={initialInstallRemoveMeterFormValues}
		summary="Fill in this form to send request to install/remove meter."
		additionFormKey="request-install-remove-meter"
		AddInstanceForm={RequestInstallRemoveMeterForm}
		addInstanceRequest={requestInstallRemoveMeter}
		successMessage="Successfully send request to install/remove meter"
		errorMessage={(errorId) =>
			`Something went wrong sending request to install/remove meter. Please contact IOPS Support, Ticket ID: ${errorId}`
		}
	/>
);
