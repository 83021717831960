import * as Yup from "yup";

/**
 * Takes a path to a field within a values parameter (usually form.values from
 * FormikProps) and validates it against a validation schema returning the
 * validated value if validation passes or undefined if validation fails.
 * @param fieldPath Path to the value within the supplied values parameter.
 * @param values Source from which to retrieve the value to be validated. This
 * can be any valid JSON value but will usually be an object, such as form.values.
 * @param validationSchema The schema with which to validate the value and perform
 * any casting.
 */
export const validatedFieldValueOrUndefinedSync = <T, TReturn = any>(
	fieldPath: string,
	values: T,
	validationSchema: Yup.Schema
) => {
	try {
		return validationSchema.validateSyncAt(fieldPath, values) as TReturn;
	} catch (err) {
		// Ignore validation error, rethrow everything else
		if (!Yup.ValidationError.isError(err)) {
			throw err;
		}
	}
	return undefined;
};
