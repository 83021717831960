import { format } from "date-fns";

import {
	BookingRequest,
	DUAL_JOB_TYPES,
	DualJob,
	ELEC_JOB_TYPES,
	ElecJob,
	GAS_JOB_TYPES,
	GasJob,
	IBookingFormValues,
	JobCodes,
	RequestType,
} from "../models/talos";
import { IAuthContext } from "../auth";

export const isAdhoc = (jobType: GasJob | ElecJob | DualJob | ""): Boolean => {
	return !!jobType && jobType.includes("Ad Hoc");
};

export const hasSubRegisters = (values: IBookingFormValues) => {
	return values.subRegisters.toLowerCase() === "y";
};

const isRequestType = (
	values: IBookingFormValues,
	requestType: RequestType
) => {
	return values.requestType.toString() === requestType.toString();
};

export const isDual = (values: IBookingFormValues) => {
	return isRequestType(values, RequestType.Dual);
};

export const isGas = (values: IBookingFormValues) => {
	return isRequestType(values, RequestType.GasOnly);
};

export const isElec = (values: IBookingFormValues) => {
	return isRequestType(values, RequestType.ElectricityOnly);
};

export const is2HourAppointment = (values: IBookingFormValues) => {
	return [
		"2 Hour Appointment Dual",
		"2 Hour Appointment",
		"Burns Test",
		"Load test (spaced test)",
		"Kettle Test",
		"Dual Photo Job",
		"Photo Job",
	].includes(values.jobType);
};

export const isTimeSlotAppointment = (values: IBookingFormValues) => {
	return [
		"Dual Photo Job",
		"Photo Job",
		"Regular Read Appointment",
		"Dual Regular Read Appointment",
		"Burns Test",
		"Load test (spaced test)",
		"Kettle Test",
	].includes(values.jobType);
};

export const getJobTypes = (values: IBookingFormValues) => {
	if (isGas(values)) {
		return GAS_JOB_TYPES;
	}

	if (isElec(values)) {
		return ELEC_JOB_TYPES;
	}

	if (isDual(values)) {
		return DUAL_JOB_TYPES;
	}

	return [];
};

export const getJobCode = (values: IBookingFormValues): JobCodes => {
	switch (values.jobType) {
		case "Ad Hoc Read Request":
			return "ADHS";
		case "Dual Ad Hoc Read Request":
			return "ADGJ";
		case "Burns Test":
			return "BURN";
		case "Kettle Test":
			return "KETT";
		case "Load test (spaced test)":
			return "SPCE";
		case "Regular Read Appointment":
			switch (values.appointmentTime) {
				case "All Day":
					return "APPD";
				case "EVE":
					return "APPE";
				case "AM":
					return "APPA";
				case "PM":
					return "APPP";
			}
			return "APPD";
		case "Dual Regular Read Appointment":
			switch (values.appointmentTime) {
				case "All Day":
					return "APDJ";
				case "EVE":
					return "APEJ";
				case "AM":
					return "APAJ";
				case "PM":
					return "APPJ";
			}
			return "APPD";
		case "2 Hour Appointment":
			return "APPT";
		case "2 Hour Appointment Dual":
			return "APTJ";
		case "Photo Job":
			return values.requestType === RequestType.GasOnly ? "SPAR" : "SPER";
		case "Dual Photo Job":
			return "SPEJ";
	}

	return "ADHS";
};

export const mapFormValuesToCMRRequest = (
	values: IBookingFormValues,
	authContext: IAuthContext
) => ({
	...mapFormValuesToRequest(values),
	agentName: authContext.userName,
	agentEmail: authContext.email,
});
export const mapFormValuesToRequest = (
	values: IBookingFormValues
): BookingRequest => {
	return {
		jobCode: getJobCode(values),
		customerName: values.customerName,
		houseNameNumber: `${values.houseNumber} ${values.houseName}`,
		address: values.address,
		postOutcode: values.postOutcode,
		postIncode: values.postIncode,
		appointmentDate: values.appointmentDate
			? format(new Date(values.appointmentDate), "yyyy-MM-dd")
			: "",
		appointmentTime: values.appointmentTime,
		earliestReadDate: values.earliestReadDate
			? format(new Date(values.earliestReadDate), "yyyy-MM-dd")
			: "",
		latestReadDate: values.latestReadDate
			? format(new Date(values.latestReadDate), "yyyy-MM-dd")
			: "",
		accessInstructions: values.accessInstructions,
		specialInstructions: values.specialInstructions,
		customerCareDetails: values.customerCareDetails,
		customerPassword: values.customerPassword,
		gasMeterData: [RequestType.Dual, RequestType.GasOnly].includes(
			values.requestType
		)
			? {
					mprn: values.mprn,
					msn: values.gas_msn,
					meterLocation: values.gas_meterLocation,
					registerId: values.gas_registerID,
					registerName: values.gas_registerName,
			  }
			: undefined,
		electricityMeterData: [
			RequestType.Dual,
			RequestType.ElectricityOnly,
		].includes(values.requestType)
			? {
					mpan: values.mpan,
					msn: values.elec_msn,
					meterLocation: values.elec_meterLocation,
					registerId: values.elec_registerID,
					registerName: values.elec_registerName,
					secondRegisterId: values.elec_secondRegisterID,
					secondRegisterName: values.elec_secondRegisterName,
			  }
			: undefined,
		sourceProcess: "IOPS Agents",
	};
};
