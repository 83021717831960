import { format, parseISO } from "date-fns";
import { FormikProps } from "formik";

/**
 * Test if the input string has leading 0s
 * @param value a string to test
 * @returns true if the string starts with a 0
 */
export const hasLeadingZeros = (value: string): boolean => {
	return value.startsWith("0");
};

/**
 * Presents a date in a uniform way
 */
export const formatDate = (date: Date | undefined): string => {
	return date ? format(date, "d MMM yyyy") : "";
};

export const formatJavaStyleDateString = (
	input: string | undefined,
	includeTime: boolean = false
): string => {
	if (!input) return "";

	const formatStr = includeTime ? "d MMM yyyy HH:mm" : "d MMM yyyy";

	try {
		return format(parseISO(input), formatStr);
	} catch {
		return "";
	}
};

export const stripSpaces = (
	e: React.ChangeEvent<HTMLInputElement>, // Typing the event
	form: FormikProps<any> // Typing the form (replace `any` with your actual form values type)
) => {
	// Strip spaces from the input value
	const strippedValue = e.target.value.replace(/\s+/g, "");

	// Update the field value in Formik state
	form.setFieldValue(e.target.name, strippedValue);
};
