import { Stack, MenuItem } from "@mui/material";
import { AxiosResponse } from "axios";
import { Formik, Form } from "formik";

import { AdditionFormProps } from "../../../form-generator";
import {
	IInstallRemoveMeter,
	RetrievalMethod,
	initialInstallRemoveMeterFormValues,
} from "../../../models/metering";
import {
	EnergisationStatus,
	CommunicationsMethod,
} from "../../../models/metering/common";
import {
	TalosTextBox,
	TalosDateField,
	OutboundTimeField,
	TalosDropDown,
	TalosRadioGroup,
	TalosButton,
} from "../../forms";
import { TalosMpanField } from "../../forms/talos-mpan-field";
import { requestInstallRemoveMeterFormValidationSchema } from "./request-install-remove-meter-form.validation-schema";

export const RequestInstallRemoveMeterForm = ({
	formSubmitMutation,
	formData,
}: AdditionFormProps<IInstallRemoveMeter, AxiosResponse>) => {
	return (
		<Formik
			onSubmit={(values: IInstallRemoveMeter, { resetForm }) => {
				return formSubmitMutation.mutate(values, {
					onSuccess: () => resetForm(),
				});
			}}
			initialValues={formData}
			validationSchema={requestInstallRemoveMeterFormValidationSchema}
			validateOnChange
			enableReinitialize
		>
			{(form) => (
				<Form>
					<Stack gap={2}>
						<TalosMpanField fieldName="mpan" label="MPAN*" form={form} />
						<TalosTextBox
							fieldName="transactionId"
							label="Transaction Id"
							form={form}
						/>
						<TalosDateField
							fieldName="appointmentDate"
							label="Appointment Date"
							form={form}
						/>
						<OutboundTimeField
							value={form.values.earliestAppointmentTime!!}
							name="earliestAppointmentTime"
							label="Earliest Appointment Time"
							helperText={form.errors.earliestAppointmentTime}
							error={!!form.errors.earliestAppointmentTime}
							form={form}
						/>
						<OutboundTimeField
							value={form.values.latestAppointmentTime!!}
							name="latestAppointmentTime"
							label="Latest Appointment Time"
							helperText={form.errors.latestAppointmentTime}
							error={!!form.errors.latestAppointmentTime}
							form={form}
						/>
						<TalosDropDown
							fieldName="retrievalMethod"
							label="Retrieval Method*"
							form={form}
							menuItems={Object.entries(RetrievalMethod).map((val, index) => {
								return (
									<MenuItem
										key={`retrieval_method_${index}`}
										value={val[0]}
										onChange={() =>
											form.setValues(initialInstallRemoveMeterFormValues)
										}
									>
										{`${val[0]} - ${val[1]}`}
									</MenuItem>
								);
							})}
						/>
						<TalosDropDown
							fieldName="requestedEnergisationStatus"
							label="Requested Energisation Status*"
							form={form}
							menuItems={Object.entries(EnergisationStatus).map(
								(val, index) => {
									return (
										<MenuItem
											key={`requested_energisation_status_${index}`}
											value={val[1]}
											onChange={() =>
												form.setValues(initialInstallRemoveMeterFormValues)
											}
										>
											{`${val[1]} - ${val[0]}`}
										</MenuItem>
									);
								}
							)}
						/>
						<TalosTextBox
							fieldName="standardSettlementConfigurationId"
							label="Standard Settlement Configuration Id"
							form={form}
						/>
						<TalosTextBox
							fieldName="additionalInformation"
							label="Additional Information"
							form={form}
							multiline
							minRows={3}
							maxRows={3}
						/>
						<TalosRadioGroup
							label="Remove Metering Point Meters"
							fieldName="removeMeteringPointMeters"
							form={form}
							row
							options={[
								{ value: "true", label: "Yes" },
								{ value: "false", label: "No" },
								{ value: "", label: "None" },
							]}
						/>
						<TalosTextBox
							fieldName="meteringSystemNonSettlementFunctionalityCode"
							label="Metering System Non-Settlement Functionality Code"
							form={form}
						/>
						<TalosDropDown
							fieldName="communicationsMethod"
							label="Communications Method"
							form={form}
							menuItems={[
								["Select", ""],
								...Object.entries(CommunicationsMethod),
							].map((val, index) => (
								<MenuItem key={`communications_method_${index}`} value={val[1]}>
									{`${val[1]}${val[1] ? ` - ${val[0]}` : val[0]}`}
								</MenuItem>
							))}
						/>
					</Stack>
					<Stack direction={"row"} gap={2} marginTop={2}>
						<TalosButton
							fieldName="form_submit"
							form={form}
							buttonText="Submit"
							loading={formSubmitMutation.isLoading}
						/>
					</Stack>
				</Form>
			)}
		</Formik>
	);
};
