import { useMemo, useState } from "react";

import { subDays } from "date-fns";

import {
	getBookingRequests,
	getMDSBookingsSearchParams,
	getSettlementsBookingRequests,
	processFilterParams,
} from "../../api/talos";
import { BookingRequestsTable } from "../../components";
import { BookingRequestResponse } from "../../models/talos";
import { PagedRequestsPage } from "../requests-list-page";
import { BookingRequestsInstructions } from "../../components/cmr/booking-request-instructions";
import {
	IMpxnByDateFilterProps,
	MpxnByDateFilter,
} from "../../components/filters/mpxn-by-date-filter";
import { useTalosFlags } from "../../flags";

export const BookingRequests = () => {
	const { uiEnableBookingRequestsFromSettlementsReads } = useTalosFlags();

	const [filterState, setFilterState] = useState<IMpxnByDateFilterProps>({
		mpxn: "",
		start: subDays(new Date(), 4),
		end: new Date(),
	});
	const { mpxn, start, end } = filterState;
	const searchParams = useMemo(
		() =>
			processFilterParams({
				fields: { mpxn, unsentOnly: false },
				dates: { startDate: start, endDate: end },
			}),
		[mpxn, start, end]
	);

	const settlementsSearchParams = useMemo(
		() => getMDSBookingsSearchParams(mpxn, start, end),
		[mpxn, start, end]
	);

	return (
		<PagedRequestsPage<BookingRequestResponse>
			title="Booking Requests"
			requestsName={"Booking Requests"}
			requestsKey={"booking-requests"}
			getRequests={
				uiEnableBookingRequestsFromSettlementsReads
					? getSettlementsBookingRequests
					: getBookingRequests
			}
			RequestsTable={BookingRequestsTable}
			additionLink={"/new-booking-request"}
			getRequestsOptions={
				uiEnableBookingRequestsFromSettlementsReads
					? settlementsSearchParams
					: searchParams
			}
			requestsOptions={
				<MpxnByDateFilter
					handleSubmit={setFilterState}
					initialValues={filterState}
					filterType="MPXN"
				/>
			}
		>
			<BookingRequestsInstructions />
		</PagedRequestsPage>
	);
};
