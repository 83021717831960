import { NewBookingRequestForm } from "../../components";
import {
	BookingRequest,
	IBookingFormValues,
	RequestType,
} from "../../models/talos";
import {
	mapFormValuesToRequest,
	mapFormValuesToCMRRequest,
} from "../../utilities";
import {
	addCMRBookingRequest,
	addSettlementsReadsBookingRequest,
} from "../../api/talos";
import { AdditionPage } from "../addition-page";
import { useTalosFlags } from "../../flags";

const initialValues: IBookingFormValues = {
	requestType: RequestType.ElectricityOnly,
	mpan: "",
	mprn: "",
	elec_msn: "",
	gas_msn: "",
	customerName: "",
	houseNumber: "",
	houseName: "",
	postOutcode: "",
	postIncode: "",
	address: "",
	accessInstructions: "",
	elec_meterLocation: "",
	gas_meterLocation: "",
	specialInstructions: "",
	customerCareDetails: "",
	customerPassword: "",
	gas_registerID: "1",
	gas_registerName: "",
	elec_registerID: "",
	elec_registerName: "ANYTIME",
	elec_secondRegisterID: "",
	elec_secondRegisterName: "",
	jobType: "",
	appointmentDate: "",
	appointmentTime: "",
	earliestReadDate: "",
	latestReadDate: "",
	subRegisters: "N",
};

export const AddBookingRequest = () => {
	const {
		uiEnableBookingRequestsFromSettlementsReads: settlementsReadsRequests,
	} = useTalosFlags();
	// Pick the correct options depending on whether we are using the CMR service
	// or the settlements reads service for booking requests based on the feature
	// flag.
	const bookingOptions = settlementsReadsRequests
		? {
				mapFormToValues: mapFormValuesToRequest,
				addInstanceRequest: addSettlementsReadsBookingRequest,
		  }
		: {
				mapFormToValues: mapFormValuesToCMRRequest,
				addInstanceRequest: addCMRBookingRequest,
		  };

	return (
		<AdditionPage<BookingRequest, IBookingFormValues>
			header="New Booking Request"
			summary="Fill in this form to request a new appointment with MDS. This will add a request to the queue and will go out in the next batch."
			additionFormKey={"add-booking-request"}
			AddInstanceForm={NewBookingRequestForm}
			initialValues={initialValues}
			listPage={{
				link: "/booking-requests",
				subject: "bookings",
				title: "Booking Requests",
			}}
			successMessage="Booking Request has been sent successfully"
			{...bookingOptions}
		/>
	);
};
