import { useCallback, useEffect } from "react";

import mixpanel from "mixpanel-browser";

import { talosConfig } from "./configuration";

const token = talosConfig.mixpanel.token;

/**
 * Initialise Mixpanel analytics with the token from the environment variables.
 */
export const useInitialiseAnalytics = () =>
	useEffect(() => {
		if (!token) {
			console.warn("Mixpanel token not found.");
			return;
		}

		mixpanel.init(token, talosConfig.mixpanel.config);

		// Register some properties we want to have sent with every event.
		mixpanel.register_once(talosConfig.mixpanel);
	}, []);

/**
 * Identify the user in Mixpanel analytics. Most likely this will be the user's
 * email address.
 */
export const identifyAnalyticsUser = (userId: string) => {
	// If mixpanel token or user ID aren't available then don't identify the user.
	if (!token || !userId) return;

	mixpanel.identify(userId);
};

const trackEventTimeStart =
	(
		eventName: string,
		eventKey: string,
		eventData: Record<string, any>,
		mixpanelDisabled: boolean
	) =>
	() => {
		// If mixpanel token is not found or analytics are disabled, do not track
		// the event.
		if (mixpanelDisabled) {
			return;
		}

		// Track the event with the key and data. Record phase start so that we can
		// differentiate between the number of started events, and the number of
		// completed events.
		mixpanel.track(eventName, {
			...eventData,
			key: eventKey,
			phase: "initiate",
		});

		// Begin event timing, this will give us an accurate duration for the event.
		mixpanel.time_event(eventName);
	};

const trackEventTimeEnd =
	(
		eventName: string,
		eventKey: string,
		eventData: Record<string, any>,
		mixpanelDisabled: boolean
	) =>
	() => {
		if (mixpanelDisabled) return;

		// Track the event with the key and data. Record phase end so that we can
		// differentiate between the number of started events, and the number of
		// completed events.
		mixpanel.track(eventName, {
			...eventData,
			key: eventKey,
			phase: "complete",
		});
	};

export const useLazyTrackEventTime = (
	eventName: string,
	eventKey: string,
	eventData: Record<string, any>,
	disableAnalytics = false
) => {
	const mixpanelDisabled = !token || disableAnalytics;

	return [
		// eslint-disable-next-line react-hooks/exhaustive-deps
		useCallback(
			trackEventTimeStart(eventName, eventKey, eventData, mixpanelDisabled),
			[eventName, eventKey, eventData, mixpanelDisabled]
		),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		useCallback(
			trackEventTimeEnd(eventName, eventKey, eventData, mixpanelDisabled),
			[eventName, eventKey, eventData, mixpanelDisabled]
		),
	];
};

/**
 * Track the duration of an event. Invoking this hook starts the timer, invoking
 * the returned function stops the timer and sends the event to Mixpanel.
 * It also accepts a boolean to disable analytics.
 * @param eventName Name of the event to track.
 * @param eventKey Key to identify the event, e.g. which form page are we tracking.
 * This data will be bundled with the event data.
 * @param eventData Additional data to track with the event.
 * @param disableAnalytics In some cases we may not want to track the event.
 * Hooks cannot be called conditionally so provide a boolean to disable tracking.
 */
export const useTrackEventTime = (
	eventName: string,
	eventKey: string,
	eventData: Record<string, any>,
	disableAnalytics = false
) => {
	const mixpanelDisabled = !token || disableAnalytics;

	// We are not passing an inline function as we trackEventTimeStart needs to
	// be used in a couple of places, need to be aware that currently we only
	// want to run this effect if the event name, key or whether mixpanel is
	// disabled or not change, other changes (e.g. to event data) should not
	// cause it to re-run.
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(
		trackEventTimeStart(eventName, eventKey, eventData, disableAnalytics),
		[eventName, eventKey, mixpanelDisabled]
	);

	return trackEventTimeEnd(eventName, eventKey, eventData, disableAnalytics);
};

export const useTrackEvent = (
	eventName: EVENT,
	eventKey: string,
	eventData: Record<string, unknown> = {},
	disableAnalytics = false
) =>
	useEffect(() => {
		// If mixpanel token is not found or analytics are disabled, do not track
		// the event.
		if (!token || disableAnalytics) return;

		mixpanel.track(eventName, { ...eventData, key: eventKey });
	}, [eventName, eventKey]); // eslint-disable-line react-hooks/exhaustive-deps

export const useLazyTrackFormCompletion = (
	formKey: string,
	eventData: Record<string, unknown> = {},
	disableAnalytics = false
) =>
	useLazyTrackEventTime(
		EVENT.FORM_COMPLETE,
		formKey,
		eventData,
		disableAnalytics
	);

export enum EVENT {
	FORM_COMPLETE = "Talos Form Complete",
	PAGE_VIEW = "Talos Page View",
}
