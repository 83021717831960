import React, { Fragment } from "react";

import { Form, Formik, FormikConfig, FormikProps, FormikValues } from "formik";
import { Box } from "@mui/material";
import { UseMutationResult } from "react-query";
import { format } from "date-fns";

import { TalosButton } from "../components";
import {
	hasErrorHandler,
	hasSuccessHandler,
	isBooleanField,
	isDateField,
	isMpanField,
	isNumericField,
	isRadioField,
	isSection,
	isSelectField,
	isStringField,
} from "./form.utilities";
import {
	AdditionFormProps,
	FieldDescription,
	FormWrapperComponent,
	InferApiFnResponse,
	SectionDescription,
	SimpleFormDescription,
} from "./form.types";
import { AdditionPage } from "../pages/addition-page";
import { generateValidationSchema } from "./validation-schema.generator";
import { addFlowRequest, API_TYPES } from "../api/talos";
import { IAuthContext } from "../auth";
import { formComponentsFromFields } from "./form-component.generator";
import {
	GeneratorContext,
	useFormAutofillWrapper,
	useOnFirstTouch,
} from "./hooks";

/**
 * Takes an array of FieldDescription or SectionDescription objects and returns
 * a tuple of strings. Each inner array represents a mapping between a field
 * name and its corresponding API property name.
 * @param fields Description of fields or sections to be mapped.
 * @returns An array of strings to string tuples representing a mapping between
 * a field name and its corresponding API property name.
 */
const fieldsToMappedApiProperty = (
	fields: (FieldDescription | SectionDescription)[]
): [string, string][] =>
	fields.flatMap((fieldOrSection) =>
		isSection(fieldOrSection)
			? fieldsToMappedApiProperty(fieldOrSection.fields)
			: // Using double brackets because of the flatMap above, otherwise the
			  // entry elements will be lifted into the top level array.
			  [
					[
						fieldOrSection.fieldName,
						fieldOrSection.alias ?? fieldOrSection.fieldName,
					],
			  ]
	);

const dateValueToApiMapper = (value: string) =>
	value ? format(new Date(value), "yyyy-MM-dd") : "";
const mpanToApiMapper = (value: string) => Number(value);
const defaultValueToApiMapper = (value: string | number) => value;

const mapperForFieldType = (
	fieldsOrSections: FieldDescription[] | SectionDescription[]
) =>
	fieldsOrSections.flatMap(
		(
			fieldOrSection: FieldDescription | SectionDescription
		): [
			string,
			(value: string) => unknown | ((value: number) => unknown)
		][] => {
			const { componentType } = fieldOrSection;

			if (componentType === "section") {
				return mapperForFieldType(fieldOrSection.fields);
			}

			const { fieldName } = fieldOrSection;

			if (componentType === "date") {
				return [[fieldName, dateValueToApiMapper]];
			}

			if (componentType === "mpan") {
				return [[fieldName, mpanToApiMapper]];
			}

			return [[fieldName, defaultValueToApiMapper]];
		}
	);

export const defaultMapperFromFields = <
	TFormValues extends FormikValues,
	TFlowRequest extends unknown
>(
	fields: FieldDescription[] | SectionDescription[]
): ((values: TFormValues) => TFlowRequest) => {
	// Create a mapping of fields to API properties.
	const fieldsMappedToApiProperties = new Map(
		fieldsToMappedApiProperty(fields)
	);
	const valueMapperToFieldName = new Map(mapperForFieldType(fields));
	return (values: TFormValues) =>
		Object.fromEntries(
			Object.entries(values).map(([fieldName, fieldValue]) => [
				// Replace the field name with the appropriate api property name
				fieldsMappedToApiProperties.get(fieldName),
				// Replace the field value with something API appropriate for the field type
				valueMapperToFieldName.get(fieldName)!(fieldValue),
			])
		);
};

const initialValuesFromFields = (
	fields: FieldDescription[] | SectionDescription[]
): [string, any][] =>
	fields.flatMap((fieldOrSection) => {
		// Recurse into sections and flatten the entries out.
		if (isSection(fieldOrSection)) {
			return initialValuesFromFields(fieldOrSection.fields);
		}

		if (fieldOrSection.defaultValue !== undefined)
			// Double-bracket syntax is to leave entry (for use with Object.fromEntries)
			// intact after flatMap.
			return [[fieldOrSection.fieldName, fieldOrSection.defaultValue]];

		switch (true) {
			case isMpanField(fieldOrSection):
			case isStringField(fieldOrSection):
			case isRadioField(fieldOrSection):
			case isSelectField(fieldOrSection):
				return [[fieldOrSection.fieldName, ""]];
			case isBooleanField(fieldOrSection):
				return [[fieldOrSection.fieldName, false]];
			case isDateField(fieldOrSection):
				return [[fieldOrSection.fieldName, undefined]];
			case isNumericField(fieldOrSection):
				return [[fieldOrSection.fieldName, 0]];
			default:
				throw Error("Unknown field type");
		}
	});

/**
 * Generates an object representing the initial values for a form based on the
 * provided form description.
 * @param formDescription Description of the form to generate initial values for.
 * @returns An object representing the initial values for the form.
 */
export const initialValuesFromFormDescription = <
	TApiRequest extends unknown,
	TFormValues extends FormikValues = FormikValues
>(
	formDescription: SimpleFormDescription<TApiRequest, TFormValues>
): TFormValues =>
	Object.fromEntries(
		initialValuesFromFields(formDescription.fields)
	) as TFormValues;

/**
 * Takes an array of form components and produces a full form component with
 * validation and submission.
 * @param formComponents Components to render into the form.
 * suitable for supplying to a React Query mutation.
 * @param formValidationSchema Validation schema for the form.
 * @param Container An optional React element which will be used to wrap the
 * form if supplied.
 */
export const simpleFormFromFormComponents = <
	TFormValues extends FormikValues,
	TResponse = Boolean
>(
	formComponents: ((props: {
		form: FormikProps<TFormValues>;
	}) => React.ReactElement)[],
	formValidationSchema: FormikConfig<TFormValues>["validationSchema"],
	Container: FormWrapperComponent = Fragment
): React.ElementType<AdditionFormProps<TFormValues, TResponse>> => {
	/**
	 * Simple form component, takes a React Query mutation for persisting the form
	 * values on the server and initial values for the form.
	 */
	return function SimpleForm({
		formSubmitMutation,
		formData,
		onFirstTouch,
	}: {
		formSubmitMutation: UseMutationResult<TResponse, Error, TFormValues>;
		formData: TFormValues;
		onFirstTouch?: () => void;
	}) {
		const handleTouched = useOnFirstTouch<TFormValues>(onFirstTouch);

		return (
			<GeneratorContext.Provider
				value={{ validationSchema: formValidationSchema }}
			>
				<Formik<TFormValues>
					enableReinitialize
					validationSchema={formValidationSchema}
					validateOnChange={true}
					initialValues={formData}
					onSubmit={(values, { resetForm }): void | Promise<any> =>
						formSubmitMutation.mutate(values, { onSuccess: () => resetForm() })
					}
				>
					{(form) => {
						handleTouched(form);

						return (
							<Container>
								<Form>
									<Box className="form-wrapper">
										<Box className="form-column">
											{formComponents.map((Component, index) => (
												<Component
													form={form}
													key={`form-component-${index}`}
												/>
											))}
											<TalosButton
												fieldName="form_submit"
												form={form}
												buttonText="Submit"
												loading={formSubmitMutation.isLoading}
											/>
										</Box>
									</Box>
								</Form>
							</Container>
						);
					}}
				</Formik>
			</GeneratorContext.Provider>
		);
	};
};

/**
 *
 * A factory function that creates a React component for a form page with
 * autofill capabilities. It leverages the `useFormAutofillWrapper` hook to
 * fetch data based on form values and automatically populate the form with the
 * retrieved data.
 *
 * @template TApiRequest - The type of the data expected by the form submission API.
 * @template TFormValues - The type of the form values managed by Formik.
 * @template TAutofillQueryParams - The type of the query parameters used for fetching autofill data.
 * @template TAutofillResponse - The type of the data returned from the autofill fetch function.
 *
 * @param {Omit<SimpleFormDescription<TApiRequest, TFormValues>, "FormWrapper">} formDescription -
 *   An object describing the form's structure, fields, and behavior. The `FormWrapper`
 *   property is omitted because it is provided by this function.
 * @param {((authContext: IAuthContext, request: TApiRequest) => Promise<unknown>) | { apiType: API_TYPES; path: string }} submission -
 *   Either a function that submits the form data to an API, or details (`apiType` and `path`)
 *   of the API call to be made.
 * @param {(authContext: IAuthContext, queryParams: TAutofillQueryParams) => Promise<TAutofillResponse>} fetchAutofillData -
 *   A function that fetches the autofill data based on the provided query parameters.
 * @param {(formValues: TFormValues) => TAutofillQueryParams} queryParameterSelector -
 *   A function that extracts the query parameters from the current form values.
 * @param {<TAutofillResponse>(response: TAutofillResponse | null, formValues: FormikProps<TFormValues>) => void} autofillFields -
 *   A function that takes the fetched autofill data and the Formik form instance and populates
 *   the form fields with the fetched data.
 *
 * @returns {() => React.ReactElement} A React component that renders the form page with autofill.
 */
export const generateFormPageWithAutoFill = <
	TApiRequest extends unknown,
	TFormValues extends FormikValues = FormikValues,
	TAutofillQueryParams extends Record<string, any> = Record<string, any>,
	TAutofillResponse = unknown
>(
	formDescription: SimpleFormDescription<TApiRequest, TFormValues>,
	/**
	 * Either a function to make an API call to submit the form, or details of the
	 * call to be made.
	 */
	submission:
		| ((authContext: IAuthContext, request: TApiRequest) => Promise<unknown>)
		| { apiType: API_TYPES; path: string },
	fetchAutofillData: (
		authContext: IAuthContext,
		queryParams: TAutofillQueryParams
	) => Promise<TAutofillResponse | null>,
	queryParameterSelector: (
		formValues: TFormValues,
		validationSchema: FormikConfig<TFormValues>["validationSchema"]
	) => TAutofillQueryParams,
	autofillFields: (
		response: TAutofillResponse | null,
		formValues: FormikProps<TFormValues>
	) => void
): (() => React.ReactElement) => {
	const AutoFillFormContainer = ({
		children,
	}: {
		children?: React.ReactNode;
	}) => {
		// Compose the autofill wrapper with the regular form description FormWrapper, if specified.
		const ExtraWrapper = formDescription.FormWrapper ?? React.Fragment;

		const InnerAutoFillFormContainer = useFormAutofillWrapper<
			TFormValues,
			TAutofillQueryParams,
			TAutofillResponse
		>(
			formDescription.formKey,
			fetchAutofillData,
			queryParameterSelector,
			autofillFields
		);
		return (
			<ExtraWrapper>
				<InnerAutoFillFormContainer>
					<>{children}</>
				</InnerAutoFillFormContainer>
			</ExtraWrapper>
		);
	};

	return generateFormPage(
		{ ...formDescription, FormWrapper: AutoFillFormContainer },
		submission
	);
};

/**
 * Generates a form page from the form description supplied.
 * @param formDescription
 * @param submission
 */
export const generateFormPage = <
	TApiRequest extends unknown,
	TFormValues extends FormikValues = FormikValues
>(
	formDescription: SimpleFormDescription<TApiRequest, TFormValues>,
	/**
	 * Either a function to make an API call to submit the form, or details of the
	 * call to be made.
	 */
	submission:
		| ((authContext: IAuthContext, request: TApiRequest) => Promise<unknown>)
		| { apiType: API_TYPES; path: string }
) => {
	const {
		formKey,
		fields,
		title,
		header,
		underlyingFlowName,
		mapFormToValues,
		newRequestText,
		listPage,
		FormWrapper,
	} = formDescription;
	const formComponents = formComponentsFromFields<TFormValues>(
		formDescription.fields
	);

	const formSubmissionFn =
		typeof submission === "function"
			? submission
			: addFlowRequest<TApiRequest>(submission.path, submission.apiType);

	type TApiResponse = InferApiFnResponse<typeof formSubmissionFn>;

	const successProps = hasSuccessHandler(formDescription)
		? { onSuccess: formDescription.onSuccess }
		: { successMessage: formDescription.successMessage };

	const errorProps = hasErrorHandler(formDescription)
		? { onError: formDescription.onError }
		: { errorMessage: formDescription.errorMessage };

	const initialValues = initialValuesFromFormDescription<
		TApiRequest,
		TFormValues
	>(formDescription);

	const Form = simpleFormFromFormComponents<TFormValues, TApiResponse>(
		formComponents,
		generateValidationSchema(formDescription),
		FormWrapper
	);

	return function AddPage() {
		return (
			<AdditionPage<TApiRequest, TFormValues, TApiResponse>
				additionInstanceName={title}
				header={header}
				additionFormKey={formKey}
				mapFormToValues={mapFormToValues ?? defaultMapperFromFields(fields)}
				summary={newRequestText}
				addInstanceRequest={formSubmissionFn}
				AddInstanceForm={Form}
				initialValues={initialValues}
				listPage={listPage}
				underlyingFlowName={underlyingFlowName}
				{...successProps}
				{...errorProps}
			/>
		);
	};
};
