import { BaseSettlementsReadsResponse } from "./base-settlements-reads";

export enum CorrectReadType {
	A = "A",
	C = "C",
	D = "D",
	F = "F",
	I = "I",
	P = "P",
	R = "R",
	// This value is deprecated
	W = "W",
}

export interface ChangeReadRequest {
	mpan: number;
	msn: string;
	readDate: string;
	correctReadType: CorrectReadType;
	reg1Data: RegisterData;
	reg2Data?: RegisterData;
}

export interface IChangeReadFormValues {
	mpan: string;
	msn: string;
	readDate: Date | undefined;
	correctReadType: CorrectReadType | undefined;
	firstRegisterId: string;
	firstRegisterIncorrectRead: number;
	firstRegisterCorrectRead: number;
	subRegisters: "Y" | "N";
	secondRegisterId?: string;
	secondRegisterIncorrectRead: number;
	secondRegisterCorrectRead: number;
}

export interface RegisterData {
	regId: string;
	regIncorrectRead: number;
	regCorrectRead: number;
}

export interface ChangeReadResponse extends BaseSettlementsReadsResponse {
	correctReadType: CorrectReadType;
	reg1Data: RegisterData;
	reg2Data?: RegisterData;
}
