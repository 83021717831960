export interface IUpdateLegacyDataItem {
	mpan: number | "";
	pcId?: string;
	pcEffectiveFromSettlementDate?: string;
	sscId?: string;
	sscEffectiveFromSettlementDate?: string;
}

export const initialUpdateLegacyDataItemFormValues: IUpdateLegacyDataItem = {
	mpan: "",
	pcId: "",
	pcEffectiveFromSettlementDate: "",
	sscId: "",
	sscEffectiveFromSettlementDate: "",
};
